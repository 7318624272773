.med-container * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
}

.med-container .hero {
  height: 100vh;
  text-shadow: 5px 5px 18px #000;
  background-size: cover;
  display: flex;
}

.med-container .logo {
  float: left;
}

.med-container .hero-txt {
  margin-right: 40px;
  float: right;
}

.med-container .hero-txt h1 {
  font-size: 28px;
  text-align: left;
  color: white;
}

.med-container .hero-txt p {
  font-size: 22px;
  width: 75%;
  color: white;
}

.med-container .hero-para {
  margin-top: 15px;
  width: 700px;
}

.med-container .hero-buttons {
  align-items: center;
}

#get-login-btn {
  background-color: black;
  text-decoration: none;
  color: white;
  padding: 0.5rem 1.3rem;
  border-radius: 10px;
  transition: 0.3s;
  width: auto;
}

#get-login-btn:hover {
  background-color: white;
  color: black;
  border: 2px solid white;
}

.med-container .hero-login-button {
  background-color: black;
  text-decoration: none;
  color: white;
  padding: 0.5rem 1.3rem;
  border-radius: 10px;
  transition: 0.3s;
}

.med-container .hero-login-button:hover {
  background-color: white;
  color: black;
  border: 2px solid white;
}

.med-container .hero-about-button {
  background-color: white;
  /*border: 2px solid black;
	 */
  border-radius: 10px;
  padding: 0.5rem 1.3rem;
  color: black;
  margin-left: 10px;
  transition: 0.3s;
}

.med-container .hero-about-button:hover {
  background-color: transparent;
  color: #fff;
  border: 2px solid white;
}

.med-container .hero-buttons {
  margin-top: 25px;
}

.med-container .Mobile-img01 {
  float: right;
}

/** Second Section **/

.med-container .second-section {
  height: 100vh;
  padding-top: 220px;
  background-size: cover;
}

.med-container .smalltext-second {
  color: white;
  /*position: absolute;*/
  margin-left: 30vh;
  margin-bottom: -2px;
  margin-top: 1vh;
}

.med-container .big-text {
  /*color: white;
	*/
  float: left;
  /*position: absolute;*/
  margin-left: 30vh;
  margin-bottom: 2vh;
  color: white;
}

.med-container .second-para {
  float: left;
  color: white;
  /*position: absolute;*/
  margin-left: 30vh;
  text-align: justfy;
  text-justify: inter-word;
  margin-right: 100vh;
}

.med-container .dual-texts {
  /*position: absolute;*/
  background: rgba(255, 255, 255, 0.22);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 15vh;
  border-radius: 20px;
  margin-left: 30vh;
  margin-right: 125vh;
  margin-bottom: 3vh;
  padding: 10px 10px;
  color: white;
}

.med-container .info2 {
  /*position: absolute;*/
  background: rgba(255, 255, 255, 0.22);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  top: 12vh;
  border-radius: 20px;
  margin-left: 30vh;
  margin-right: 150vh;
  padding: 5px 5px;
  color: rgba(204, 236, 255, 0.855);
}

.med-container .infotitle {
  /*position: absolute;*/
  top: 3px;
}

.med-container .infopara {
  /*position: absolute;*/
  font-size: 15px;
}

.med-container .second-about {
  background-color: white;
  border-radius: 10px;
  padding: 0.5rem 1.3rem;
  color: black;
  margin-left: 32vh;
  transition: 0.3s;
}

.med-container .second-register {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 10px;
  padding: 0.5rem 1.3rem;
  color: white;
  margin-left: 10px;
  transition: 0.3s;
  width: auto;
}

.med-container .second-register i {
  transform: rotate(-90deg);
}

.med-container .second-about:hover {
  background-color: transparent;
  color: #fff;
  border: 2px solid white;
}

.horizontal-line {
  visibility: hidden;
}

.med-container .second-register:hover {
  background-color: white;
  color: black;
}

.med-container .third-section {
  height: 100vh;
  margin-top: 20vh;
}

.med-container .big-text-title {
  /*color: white;
	*/
  float: left;
  margin-left: 30vh;
  margin-right: 30vw;
  text-align: left;
  color: white;
}

.med-container .dual-texts-third {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 15vh;
  border-radius: 20px;
  margin-left: 30vh;
  margin-right: 125vh;
  margin-bottom: 3vh;
  padding: 10px 10px;
  color: white;
}

/** Card Section **/

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;
}

.card {
  width: 325px;
  background-color: #22304b;
  color: white;
  border-radius: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  /*overflow: hidden;*/
  margin: 10px;
  transition: 0.3s ease;
}

.card:hover {
  background-color: #1c2331;
}

.card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
}

.card-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  background: linear-gradient(rgb(255, 142, 142), rgb(255, 223, 141));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-content p {
  font-size: 14px;
  margin-bottom: 20px;
  background: linear-gradient(rgb(151, 144, 255), rgb(141, 66, 255));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-button {
  display: inline-block;
  padding: 15px 35px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 12px;
  width: auto;
}
.card-button::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #3ca69a 0%, #188578 100%);
  left: 0;
  top: 0;
  border-radius: 10px;
}
/* glow */
.card-button::before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
  opacity: 0;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* hover */
.card-button:hover::before {
  opacity: 1;
}

.card-button:active:after {
  opacity: 0.4;
}

.card-button:active {
  color: #ffffff;
  /*font-weight: bold;*/
}

/*** ***/

.ending-section {
  color: white;
  text-align: center;
  height: 60vh;
  padding-top: 20vh;
}

.ending-buttons {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: auto;
  margin-top: 30px;
}

@media (max-width: 1200px) {
  .Mobile-img01,
  .second-section,
  .third-section,
  .hero-txt,
  .dual-texts-third {
    display: none;
  }
  .hero-para {
    font-size: small;
  }

  .logo {
    max-width: 100%;
    height: auto;
    object-fit: scale-down;
  }
}
@media (max-width: 1300px) {
  .dual-texts-third,
  .dual-texts {
    display: none;
  }
  .second-para {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .horizontal-line {
    visibility: visible;
    border: 10px solid #266c64;
    border-radius: 5px;
  }
}


/** Testimonial **/

.testimonial-card {
    width: 325px;
    /*background-color: #3ca69a;*/
    color: white;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    /*overflow: hidden;*/
    margin: 10px;
    transition: 0.3s ease;

  
  background: #087b6e; /*rgba(60, 166, 154, 0.75);*/
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(60, 166, 154, 0.3);
 }
 
 /*.testimonial-card:hover {
  background-color: #043e37;
 }*/
 
 .testimonial-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
 }

 .testimonial-content {
  padding: 20px;
}
 
 .testimonial-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
    background: linear-gradient(rgb(140, 139, 211), rgb(255, 255, 255));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
 }
 
 .testimonial-para {
   font-size: 14px;
    margin-bottom: 20px;
    color: white;
    /*background: linear-gradient(rgb(151, 144, 255), rgb(141, 66, 255));

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;*/
 }

 .testimonial-role {
    font-size: 14px !important;
    font-weight: bold;
    margin-bottom: 20px;
    color: white !important; 
    /*background: linear-gradient(rgb(151, 144, 255), rgb(141, 66, 255));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;*/
 }
 
 .testimonial-card::before {
  content: "";
  background: linear-gradient(
    45deg,
    #087b6e,
    #2d857a,
    #13b09e,
    #48afa3,
    #1d4d47,
    #21524d,
    #0aa794,
    #274744
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
  opacity: 0;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.testimonial-card:hover::before {
  opacity: 1;
}

.testimonial-card:active:after {
  opacity: 1;
}

