/* General container styling */

.container {
  max-width: 960px;
  /* Adjust the width of the container */
  margin: 0 auto;
  /* Center the container horizontally */
}

/* Title section styling */

.title {
  margin-bottom: 1.5rem !important;
  /* Space below the title section */
  align-items: center !important;
  /* Vertically align items in the title section */
}

/* Title text styling */

.title h1 {
  font-size: 2rem !important;
  /* Increase font size for the main title */
  margin: 0 !important;
  /* Remove default margin */
}

/* Button in the title section */

.title .btn {
  font-size: 1.3rem !important;
  /* Custom font size for the button */
  width: 150px !important;
  /* Set a fixed width for the button */
}

/* Card component styling */

.card {
  border-radius: 0.5rem !important;
  /* Add a slight rounding to the card corners */
  border: 1px solid #dee2e6 !important;
  /* Light border around the card */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
  /* Subtle shadow for depth */
  margin-bottom: 1rem !important;
  /* Space between cards */
}

/* Card body styling */

.card-body {
  padding: 1.5rem !important;
  /* Add padding inside the card */
  display: flex !important;
  /* Use flexbox for layout */
  justify-content: space-between !important;
  /* Distribute space between text and buttons */
  align-items: center;
}

/* Text content within the card */

.text {
  flex-grow: 1 !important;
  /* Allow the text section to grow and take up available space */
  margin-right: 1.5rem !important;
  /* Space between text and buttons */
}

/* Card title styling */

.card-title {
  font-size: 1.5rem !important;
  /* Increase the font size for the card title */
  margin-bottom: 0.5rem !important;
  /* Space below the title */
}

/* Card text styling */

.card-text {
  font-size: 1rem !important;
  /* Standard font size for the text */
  margin-bottom: 0.75rem !important;
  /* Space between paragraphs */
  color: #ffffff !important;
  /* Use a muted color for the text */
}

/* Button group inside the card */

.buttons {
  display: flex !important;
  /* Use flexbox for layout */
  flex-direction: column !important;
  /* Stack buttons vertically */
  justify-content: space-around !important;
  /* Distribute space evenly */
  gap: 10px;
}

/* Individual buttons in the card */

.buttons .btn {
  width: 100px !important;
  /* Fixed width for the buttons */
  font-size: 1rem !important;
  /* Standard font size for buttons */
  margin-bottom: 0.5rem !important;
  /* Space between stacked buttons */
}

/* Last button in the column has no bottom margin */

.buttons .btn:last-child {
  margin-bottom: 0 !important;
}

/* Empty state message styling */

.text-center {
  color: #ffffff !important;
  /* Muted color for the text */
  font-style: italic !important;
  /* Italicize the message */
  margin-top: 5rem !important;
  /* Add margin above the message */
  margin-bottom: 5rem !important;
  /* Add margin below the message */
}

/* Font size for the empty state message */

.fs-4 {
  font-size: 1.25rem !important;
  /* Slightly larger font for visibility */
}

/* Margin-end spacing utility class */

.me-2 {
  margin-right: 0.5rem !important;
  /* Adds a margin to the right of the element */
}

/* Button variant for success */

.btn-success {
  background-color: #28a745 !important;
  /* Bootstrap green for success */
  border-color: #28a745 !important;
  /* Match border color */
  color: #fff !important;
  /* White text */
}

.btn-success:hover {
  background-color: #218838 !important;
  /* Darker green on hover */
  border-color: #1e7e34 !important;
  /* Darker border on hover */
}

/* Button variant for primary */

.btn-primary {
  background-color: #007bff !important;
  /* Bootstrap blue for primary */
  border-color: #007bff !important;
  /* Match border color */
  color: #fff !important;
  /* White text */
}

.btn-primary:hover {
  background-color: #0069d9 !important;
  /* Darker blue on hover */
  border-color: #0062cc !important;
  /* Darker border on hover */
}

/* Button variant for warning */

.btn-warning {
  background-color: #ffc107 !important;
  /* Bootstrap yellow for warning */
  border-color: #ffc107 !important;
  /* Match border color */
  color: #212529 !important;
  /* Dark text */
}

.btn-warning:hover {
  background-color: #e0a800 !important;
  /* Darker yellow on hover */
  border-color: #d39e00 !important;
  /* Darker border on hover */
}

/* Button variant for danger */

.btn-danger {
  background-color: #dc3545 !important;
  /* Bootstrap red for danger */
  border-color: #dc3545 !important;
  /* Match border color */
  color: #fff !important;
  /* White text */
}

.btn-danger:hover {
  background-color: #c82333 !important;
  /* Darker red on hover */
  border-color: #bd2130 !important;
  /* Darker border on hover */
}

/* Custom margin classes */

.mt-1 {
  margin-top: 0.25rem !important;
  /* 4px */
}

.mt-2 {
  margin-top: 0.5rem !important;
  /* 8px */
}

.mt-3 {
  margin-top: 1rem !important;
  /* 16px */
}

.mt-4 {
  margin-top: 1.5rem !important;
  /* 24px */
}

.mt-5 {
  margin-top: 3rem !important;
  /* 48px */
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}
