* {
    font-family: 'Poppins', sans-serif;
}

main {
    margin-top: 120px;
}

.log-sign {
    display: flex;
    justify-content: center;
    align-items: center;
}

.log-sign .logged-user-details img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px;
}

.log-sign .logged-user-details button {
    background-color: #69bde7;
    color: #fff;
    padding: .3rem 0.5rem;
    border-radius: 2rem;
    font-size: .8rem;
    margin-left: 5px;
    width: 80px;
}

.log-sign .logged-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.log-sign .logged-user-details {
    display: flex;
    align-items: center;
}

.log-sign .logged-user-details h2 {
    text-wrap: nowrap;
    font-size: 1.5rem;
    margin-left: 8px;
}