@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.med-container ul {
    list-style: none;
}

.med-container a {
    text-decoration: none;
}

.med-container header {
    position: fixed;
    top: 0px;
    background-color: #3ca69a;
    /*linear-gradient(90deg, #3CA69A 0%, #F6F6F6 100%);
	 */
    width: 100%;
    z-index: 1000;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.med-container .container {
    /* max-width: 65rem;
	 */
    padding: 0 2rem;
    margin: 0 auto;
    display: flex;
    position: relative;
    justify-content: center;
}

.med-container .logo-container {
    flex: 1;
    display: flex;
    align-items: center;
}

.med-container .nav-btn {
    flex: 3;
    display: flex;
    align-items: center;
}

.med-container .nav-links {
    flex: 3;
}

.med-container .log-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.med-container .logo {
    text-decoration: none;
}

.med-container .logo span {
    font-weight: 300;
}

.med-container .btn.solid,
.med-container .btn.transparent {
    display: inline-block;
    padding: 0.5rem 1.3rem;
    font-size: 0.8rem;
    border: 2px solid #fff;
    border-radius: 2rem;
    line-height: 1;
    margin: 0 0.2rem;
    transition: 0.3s;
}

.med-container .btn.solid,
.med-container .btn.transparent:hover {
    background-color: #fff;
    color: #69bde7;
}

.med-container .btn.transparent,
.med-container .btn.solid:hover {
    background-color: transparent;
    color: #fff;
    width: 95px;
}

.med-container .nav-links>ul {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 3vh;
	 */
}

.med-container .nav-link {
    position: relative;
    margin: auto 10px;
    /* Gradient - #0E4043 to #00ACB7 */
    background-image: linear-gradient(45deg, #0e4043 0%, #00acb7 100%);
    border-radius: 2rem;
    padding: 1px 2px;
    transition: 0.5s;
}

.med-container .nav-link>a {
    line-height: 3rem;
    color: #fff;
    padding: 0 1.3rem;
    letter-spacing: 1px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s;
    text-wrap: nowrap;
}

.med-container .nav-link>a>i {
    margin-left: 0.2rem;
}

.med-container .nav-link:hover {
    background-image: linear-gradient(45deg, #0e4043 0%, #00acb7 80%);
}

.med-container .nav-link:hover>a {
    transform: scale(1.1);
}

.med-container .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 10rem;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.med-container .dropdown ul {
    position: relative;
}

.med-container .dropdown-link>a {
    display: flex;
    /*background-color: #69c6bb;
	*/
    background-image: linear-gradient(45deg, #0e4043 0%, #00acb7 80%);
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
}

.med-container .dropdown-link:hover>a {
    background-image: linear-gradient(45deg, #0e4043 0%, #00acb7 60%);
    color: #fff;
    transition: 0.3s;
}

.med-container .dropdown-link:not(:nth-last-child(2)) {
    border-bottom: 1px solid #efefef;
}

.med-container .dropdown-link i {
    transform: rotate(-90deg);
}

.med-container .arrow {
    position: absolute;
    width: 11px;
    height: 11px;
    top: -5.5px;
    left: 32px;
    background-color: #fff;
    transform: rotate(45deg);
    cursor: pointer;
    transition: 0.3s;
    z-index: -1;
}

.med-container .dropdown-link:first-child:hover~.arrow {
    background-color: #3498db;
}

.med-container .dropdown-link {
    position: relative;
}

.med-container .dropdown.second {
    top: 0;
    left: 100%;
    padding-left: 0.8rem;
    cursor: pointer;
    transform: translateX(10px);
}

.med-container .dropdown.second .arrow {
    top: 10px;
    left: -5.5px;
}

.med-container .nav-link:hover>.dropdown,
.med-container .dropdown-link:hover>.dropdown {
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
}

.med-container .hamburger-menu-container {
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.med-container .hamburger-menu {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.med-container .hamburger-menu div {
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    z-index: 1001;
    transition: 0.5s;
}

.med-container .hamburger-menu div:before,
.med-container .hamburger-menu div:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: #fff;
    border-radius: 3px;
    transition: 0.5s;
}

.med-container .hamburger-menu div:before {
    transform: translateY(-7px);
}

.med-container .hamburger-menu div:after {
    transform: translateY(7px);
}

.med-container #check {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
    display: none;
}

.med-container #check:checked~.hamburger-menu-container .hamburger-menu div {
    background-color: transparent;
}

.med-container #check:checked~.hamburger-menu-container .hamburger-menu div:before {
    transform: translateY(0) rotate(-45deg);
}

.med-container #check:checked~.hamburger-menu-container .hamburger-menu div:after {
    transform: translateY(0) rotate(45deg);
}

.med-container .log-sign .logged-user-details img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px;
}

.med-container .log-sign .logged-user-details button {
    background-color: #69bde7;
    color: #fff;
    padding: 0.3rem 0.5rem;
    border-radius: 2rem;
    font-size: 0.8rem;
    margin-left: 5px;
    width: 80px;
}

.med-container .log-sign .logged-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.med-container .log-sign .logged-user-details {
    display: flex;
    align-items: center;
    padding: 2px 2px;
    margin-top: 4px;
    margin-bottom: 4px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.9px);
    -webkit-backdrop-filter: blur(6.9px);
    border: 1px solid rgba(255, 255, 255, 1);
}

.med-container .log-sign .logged-user-details h2 {
    text-wrap: nowrap;
    font-size: 1.5rem;
    margin-left: 8px;
    margin-right: 8px;
}

@keyframes animation {
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (max-width: 1185px) {
    /*920*/
    .logo-container {
        display: flex !important;
    }
    .med-container .hamburger-menu-container {
        display: flex;
    }
    .med-container #check {
        display: block;
    }
    .med-container .nav-btn {
        position: fixed;
        height: calc(100vh - 3rem);
        top: 3rem;
        left: 0;
        width: 100%;
        background-image: linear-gradient(90deg, #3ca69a 0%, #f6f6f6 100%);
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow-x: hidden;
        overflow-y: auto;
        transform: translateX(100%);
        transition: 0.65s;
    }
    .med-container #check:checked~.nav-btn {
        transform: translateX(0);
    }
    .med-container #check:checked~.nav-btn .nav-link,
    .med-container #check:checked~.nav-btn .log-sign {
        animation: animation 0.5s ease forwards var(--i);
    }
    .med-container .nav-links {
        flex: initial;
        width: 100%;
    }
    .med-container .nav-links>ul {
        flex-direction: column;
    }
    .med-container .nav-link {
        width: 100%;
        opacity: 0;
        transform: translateY(15px);
    }
    .med-container .nav-link>a {
        line-height: 1;
        padding: 1.6rem 2rem;
    }
    .med-container .nav-link:hover>a {
        transform: scale(1);
        background-color: #50a9d6;
    }
    .med-container .dropdown,
    .med-container .dropdown.second {
        position: initial;
        top: initial;
        left: initial;
        transform: initial;
        opacity: 1;
        pointer-events: auto;
        width: 100%;
        padding: 0;
        background-color: #3183ac;
        display: none;
    }
    .med-container .nav-link:hover>.dropdown,
    .med-container .dropdown-link:hover>.dropdown {
        display: block;
    }
    .med-container .nav-link:hover>a>i,
    .med-container .dropdown-link:hover>a>i {
        transform: rotate(360deg);
    }
    .med-container .dropdown-link>a {
        background-color: transparent;
        color: #fff;
        padding: 1.2rem 2rem;
        line-height: 1;
    }
    .med-container .dropdown.second .dropdown-link>a {
        padding: 1.2rem 2rem 1.2rem 3rem;
    }
    .med-container .dropdown.second .dropdown.second .dropdown-link>a {
        padding: 1.2rem 2rem 1.2rem 4rem;
    }
    .med-container .dropdown-link:not(:nth-last-child(2)) {
        border-bottom: none;
    }
    .med-container .arrow {
        z-index: 1;
        background-color: #69bde7;
        left: 10%;
        transform: scale(1.1) rotate(45deg);
        transition: 0.5s;
    }
    .med-container .nav-link:hover .arrow {
        background-color: #50a9d6;
    }
    .med-container .dropdown .dropdown .arrow {
        display: none;
    }
    .med-container .dropdown-link:hover>a {
        background-color: #3a91bd;
    }
    .med-container .dropdown-link:first-child:hover~.arrow {
        background-color: #50a9d6;
    }
    .med-container .nav-link>a>i {
        font-size: 1.1rem;
        transform: rotate(-90deg);
        transition: 0.7s;
    }
    .med-container .dropdown i {
        font-size: 1rem;
        transition: 0.7s;
    }
    .med-container .log-sign {
        flex: initial;
        width: 100%;
        padding: 1.5rem 1.9rem;
        justify-content: flex-start;
        opacity: 0;
        transform: translateY(15px);
    }
}

.cart-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0.3rem 0.6rem !important;
    font-size: 0.75rem;
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
}