.med-container .tracker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.med-container .search-bar {
  display: flex;
  margin-bottom: 20px;
}

.med-container .search-bar input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  flex: 1;
}

.med-container .search-bar button {
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.med-container .search-bar button:hover {
  background-color: #218838;
}

.med-container .tracker-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
  border-radius: 50px;
}

.med-container .hospital-list {
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.med-container .hospital-list h2 {
  margin-bottom: 20px;
}

.med-container .hospital-list ul {
  list-style: none;
  padding: 0;
}

.med-container .hospital-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.med-container .hospital-list li:last-child {
  border-bottom: none;
}

.med-container .hospital-list .buttons {
  display: flex;
  flex-direction: column;
}

.med-container .hospital-list button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.med-container .hospital-list button:hover {
  background-color: #45a049;
  /* Darker shade of green on hover */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* Shadow effect on hover */
}

.med-container .hospital-list button:focus,
.med-container .hospital-list button:active {
  outline: none;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #4caf50;
}

@media (max-width: 990px) {
  .med-container .hospital-list {
    width: 80%;
    margin: auto;
  }
  .med-container .hospital-list li {
    flex-direction: column;
  }
  .med-container .tracker-content {
    flex-direction: column-reverse;
  }
  .med-container .hospital-list button {
    padding: 5px 30px;
    margin: 2px;
  }
  .med-container p {
    margin-bottom: 0px;
  }
}
