.posBody {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f9;
    color: #333;
    display: 'flex';
}

.posContainer {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
}

.posBody main {
    margin-top: 0;
}

.posBody .pos_container {
    max-width: 90%;
    margin: 30px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.posBody .pos_container h1 {
    text-align: center;
    color: #007bff;
    margin-bottom: 20px;
    font-weight: 700;
}

.posBody .add-medicine-btn {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 20px auto 40px;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.posBody .add-medicine-btn:hover {
    background-color: #0056b3;
}

.posBody .main-content {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.posBody .cart-section {
    width: 48%;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9fb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.posBody .cart-section h2 {
    font-size: 22px;
    color: #007bff;
    margin-bottom: 20px;
}

.posBody .medicine-section {
    width: 48%;
}

.posBody .medicine-section h2 {
    font-size: 22px;
    color: #007bff;
    margin-bottom: 20px;
}

.posBody .search-container {
    margin-bottom: 20px;
}

.posBody input[type="text"],
.posBody input[type="number"] {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s ease;
    box-sizing: border-box;
}

.posBody .pos_container input[type="text"]:focus,
.posBody .pos_container input[type="number"]:focus {
    border-color: #007bff;
    outline: none;
}

.posBody .medicines-list {
    margin-top: 20px;
}

.posBody .medicine-item,
.posBody .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #f4f4f9;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.posBody .medicine-item:hover,
.posBody .cart-item:hover {
    background-color: #e9e9ef;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.posBody button {
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.posBody .pos_container button:hover {
    background-color: #0056b3;
}

.posBody button:disabled {
    background-color: #ccc !important;
    cursor: not-allowed;
}

.posBody .pos_container .remove-item-btn {
    background-color: transparent;
    border: none;
    color: #f00;
    font-size: 14px;
    cursor: pointer;
    padding: 0 10px;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.posBody .remove-item-btn:hover {
    color: #d00000;
}

.posBody .purchase-button {
    width: 100%;
    padding: 14px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 8px;
    background-color: #28a745;
    margin-top: 20px;
}

.posBody .purchase-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.posBody .purchase-button:hover:not(.disabled) {
    background-color: #218838;
}

.posBody table {
    width: 95%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
    margin: 20px auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.posBody thead {
    background-color: #2c3e50;
    color: white;
    text-align: left;
    font-weight: bold;
}

.posBody thead th {
    padding: 12px 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.posBody tbody tr {
    border-bottom: 1px solid #ddd;
}

.posBody tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.posBody tbody tr:hover {
    background-color: #e1e1e1;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.posBody tbody td {
    padding: 12px 15px;
    color: #333;
    font-weight: bold;
}

.posBody tbody td buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.posBody tbody td button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 10px;
}

.posBody button.danger {
    background-color: #dc3545;
}

.posBody .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.posBody .modal-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    width: 320px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.posBody .modal-content input {
    box-sizing: border-box;
    padding: 8px;
    width: 100%;
    margin-bottom: 15px;
}

.posBody .modal-content button {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.posBody .modal-content button:last-child {
    background-color: #dc3545;
}

.posBody .modal-content button:last-child:hover {
    background-color: #c82333;
}

.posBody .pos_container h2 {
    text-align: center;
}