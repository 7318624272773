.med-container {
    font-family: Arial, sans-serif;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /*background-image: linear-gradient(90deg, #70e1f5 0%, #ffd194 100%);*/
    background-color: #91DDCF;
    flex-direction: column;
    padding-top: 120px;
}

.med-container h1 {
    margin-bottom: 10px;
    text-align: center;
    font-size: 30px;
}

.med-container .input-group {
    position: relative;
    margin-bottom: 20px;
}

.med-container .input-group input {
    width: 400px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.med-container button {
    width: 100%;
    padding: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.med-container button:hover {
    background-color: #0056b3;
}

.med-container .loginboxFooter {
    margin-top: 20px;
}

.med-container .log-med {
    width: 300px;
    margin: 150px 0 0 200px;
    float: left;
}

.med-container .reg-med {
    width: 350px;
    margin: 150px 0 0 200px;
    float: left;
}

.med-container .login-form {
    background-image: linear-gradient(90deg, #2aa798 0%, #2a71c8 100%);
    float: right;
    padding: 40px 40px 40px 40px;
    margin: 150px 200px 0 0;
    border-radius: 40px;
}

.med-container .reg-form {
    background-image: linear-gradient(90deg, #2aa798 0%, #2a71c8 100%);
    float: right;
    margin: 110px 200px 0 0;
    padding: 40px 40px 40px 40px;
    border-radius: 40px;
}

.med-container .registerboxFooter .reg-box {
    float: left;
    color: #fff;
}

.med-container .registerboxFooter .fp-box {
    color: #fff;
    float: right;
}

.med-container .loginboxFooter .log-box {
    color: #fff;
    float: left;
}

.med-container .loginboxFooter .home-box {
    color: #fff;
    float: right;
}